.btn-wait {
    border-radius: 160px;
    background: rgb(34, 36, 54);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px 75px 15px 75px;
    font-size: 15px;
    font-family: Satoshi;
}

.btn-load {
    opacity: 75%;
}