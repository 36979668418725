.login-logo {
    text-align: -webkit-center;
}

.login-text {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;

}