.btn_cont {
    position: relative;
    display: inline-flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
}

.btn1 {
    box-sizing: border-box;
    border-radius: 39.2px;
    background: rgb(34, 36, 54);
    padding: 13px 44px 14px 21px;
    color: rgb(255, 255, 255);
    font-family: 'Satoshi', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 10.5px;
    letter-spacing: 0;
    text-align: left;
}

.btn2 {
    box-sizing: border-box;
    border: 0.7px solid rgb(255, 255, 255);
    border-radius: 39.2px;
    background: rgb(99, 148, 24);
    position: absolute;
    /* z-index: 10; */
    /* padding: 10px 20px; */
    color: rgb(255, 255, 255);
    font-family: 'Satoshi', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 10.5px;
    letter-spacing: 3px;
    width: 38px;
    height: 38px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.btn2 img {
    width: 20px;
    height: 20px;
    z-index: 11;
}

@media (min-width: 765px) {
    .btn1 {
        padding: 24px 77px 23px 57px;
        font-size: 16px;
    }

    .btn2 {

        width: 60px;
        height: 60px;
    }

    .btn2 img {
        width: 25px;
        height: 25px;
        z-index: 11;
    }
}